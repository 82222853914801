import * as React from "react";
import {InterviewsSection, Intro, P, Title} from "./style";
import {Pagination, A11y, Navigation} from "swiper";
import {  Swiper, SwiperSlide } from 'swiper/react';
import annaImg from '../../images/HES-SO_INTERVIEWS_ANA.jpg';
import clementImg from '../../images/HES-SO_INTERVIEWS_CLEMENT.jpg';
import fredericImg from '../../images/HES-SO_INTERVIEWS_FREDERIC.jpg';
import ninotchkaImg from '../../images/HES-SO_INTERVIEWS_NINOTCHKA.jpg';
import simonImg from '../../images/HES-SO_INTERVIEWS_SIMON.jpg';
import valerieImg from '../../images/HES-SO_INTERVIEWS_VALERIE.jpg';
import YouTube from "react-youtube";
import {useRef, useState} from "react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {StarIcon} from "../../styles/icons";
import {breakpoints} from "../../styles/breakpoints";

const slides = [
    {
        image: annaImg,
        video:'Cm-mYLuIv0Q'
    },
    {
        image: clementImg,
        video:'IAY2XMqiAJ0'
    },
    {
        image: ninotchkaImg,
        video:'C-80lxnEBus'
    },
    {
        image: simonImg,
        video:'ZupJsNpXae8'
    },
    {
        image: valerieImg,
        video:'FAXptnQx280'
    },
    {
        image: fredericImg,
        video:'J-1Cs7vS3_Q'
    },

];

//  clément/1 => head,  ninotchka/2 => heg, simon/3 => hepia,
let swiper,  setSwiper;
export const goToInterview = (index) => {
    if (window && window.innerWidth > 1024)
        index = index -1;
    if(swiper)
        swiper.slideToLoop(index)
}

export const Interviews = ({index}) => {
    //const [displayVideo, setDisplayVideo] = useState(null);
    //const slideRef = useRef()
     [swiper, setSwiper] = useState(null);

    //const slideTo = (index) => {

    //};

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };
    const _onReady = function(event) {
            //event
            //console.log("That's my event ",event);
            // access to player in all event handlers via event.target
            //event.target.pauseVideo();
    }
   // console.log("Display videos ",displayVideo);
    return <>
        <InterviewsSection id={"interviews"}>
            <Intro>
                <Title>Les interviews de nos <StarIcon /> étudiant·e·s et nos diplômé·e·s</Title>
                <P>Elles et ils suivent des études ou les ont terminées et sont pleinement satisfait•e•s de leurs parcours à la HES-SO Genève : des expériences aussi inspirantes que motivantes !</P>
            </Intro>
            <Swiper onSwiper={setSwiper}  modules={[ Pagination, A11y, Navigation]} navigation center loop breakpoints={ { 320 : {slidesPerView: 1}, 768: {slidesPerView: 2} , 1024: {slidesPerView: 3} } } slidesPerView={3} spaceBetween={60} pagination={{ clickable: true }} className={"videoInterviews"} >
                {slides.map((slide, index) => (
                    <SwiperSlide key={"slide"+index} className={"slide slide"+index} >
                        <img src={slide.image} alt={"Youtube video"}   />
                        <YouTube videoId={slide.video} opts={opts} alt={"Youtube video"}  containerClassName={"video"} onReady={_onReady}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </InterviewsSection>
    </>
}