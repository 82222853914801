import * as React from "react";
import {FilieresSection, Links, Title, HeadIconLink, HepiaIconLink, HegIconLink} from "./style";


export const Filieres = (props) => {
    return <FilieresSection>
            <Title>Découvre les filières</Title>
            <Links>
                <HeadIconLink width={'13rem'} href={"https://www.hesge.ch/head/formations-recherche/bachelor-en-communication-visuelle"} target={"_blank"}>HEAD</HeadIconLink>
                <HepiaIconLink width={'13rem'} href={"https://www.hesge.ch/hepia/bachelor/informatique-et-systemes-communication"} target={"_blank"}>HEPIA</HepiaIconLink>
                <HegIconLink width={'13rem'} href={"https://www.hesge.ch/heg/formations/bachelors/informatique-gestion"} target={"_blank"}>HEG</HegIconLink>
            </Links>
    </FilieresSection>
}