import * as React from "react";
import {ConstrainedSection} from "../../styles/global";
import {H1, Icons, P} from "./style"
import {FolderIcon, HandIcon, HeadIconLink, HegIconLink, HepiaIconLink} from "../../styles/icons";


export const Intro = () => {
    return <ConstrainedSection>
        <H1>
            Tu es juste à quelques clics <HandIcon/> <br/>
            de découvrir nos <FolderIcon/> formations numériques !
        </H1>
        <P>
            Le numérique, c’est partout et c’est pour toi ! <br />
            De la cybersécurité aux réseaux sociaux, des apps mobiles à la réalité virtuelle, ta génération vit au cœur de cet univers qui a besoin d’une multitude de compétences techniques, artistiques ou d’organisation. À la HES-SO Genève, tu peux intégrer une formation de pointe, niveau Bachelor et Master, qui t’assure une carrière passionnante dans ce domaine en constante évolution. À toi de jouer, change le code !
        </P>
        <Icons>
            <HepiaIconLink className={"hepia"} to={"hepia"} hashSpy spy smooth offset={-100}>HEPIA</HepiaIconLink>
            <HeadIconLink className={"head"} to={"head"} hashSpy spy smooth offset={-100}>HEAD</HeadIconLink>
            <HegIconLink className={"heg"} to={"heg"} hashSpy spy smooth offset={-100}>HEG</HegIconLink>
        </Icons>
    </ConstrainedSection>
}