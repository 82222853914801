import styled from "@emotion/styled";

import arrowDownSvg from '../../images/arrow-down.svg'

const Section = styled.section`
/*normally use body*/
      width: 100%;
      height: 80vh;   
      background-color: ${ props => props.theme.colors.lightBlue };
      position: relative;
      overflow: hidden;
    .container {
      position: absolute;
      top: 50%;
      margin-top: -4.76rem;
      left: 50%;
      margin-left: -9.65rem;
    }
    .eye {
      position: relative;
      display: inline-block;
      border-radius: 50%;
      height: 8.5rem;
      width: 8.5rem;
      background: #fff;
      :first-of-type {
        margin-right: 2.3rem;
      }
    }
    .eye:after { /*pupil*/
      position: absolute;
      bottom: 4.85rem;
      right: 2.85rem;
      width: 2.85rem;
      height: 2.85rem;
      background: #000;
      border-radius: 50%;
      content: " ";
      
    }
`

const Arrow = styled.span`
  @keyframes scroll-anim {
    0%, 17%, 34%, 100%  {
      -webkit-transform: translateY(0);  /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: translateY(0);  /* IE 9 */
      transform: translateY(0);  /* Firefox 16+, IE 10+, Opera */
    }
    12%, 29% {
      -webkit-transform: translateY(15%);  /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: translateY(15%);  /* IE 9 */
      transform: translateY(15%);  /* Firefox 16+, IE 10+, Opera */
    }
  }
  
  width: 51px;
  height: 76px;
  display: block;
  background: transparent url(${arrowDownSvg}) center center no-repeat;
  position: absolute;
  left: 50%;
  margin-left: -25px;
  bottom: 20px;
  animation: scroll-anim 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

`

export {Section, Arrow};