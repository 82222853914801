import styled from "@emotion/styled";
import {css} from "@emotion/react";
import eye1Img from "../../images/HES-SO_YEUX_1.png"
import eye2Img from "../../images/HES-SO_YEUX_2.png"
import eye3Img from "../../images/HES-SO_YEUX_3.png"

export const marqueeStyle = theme => css`

    background-color: ${theme.colors.blue};
    .marquee {
      padding: 1rem 0;
    }
`



export const Label = styled.div`
  font-family: ${prop => prop.theme.fonts.subtitle.family};
  color: ${prop => prop.theme.colors.lightBlue};
  font-size: 2rem;
  height: 71px;
  line-height: 71px;
  padding: 0 1rem;
`

const Eye = styled.div`
  width: 154px;
  height: 71px;
  background-size: 100% 100%;
  background-position: center center;
`

export const Eye1 = styled(Eye)`
  background-image: url(${eye1Img});
`
export const Eye2 = styled(Eye)`
  background-image: url(${eye2Img});
`
export const Eye3 = styled(Eye)`
  background-image: url(${eye3Img});
`

export const eyes = [Eye1, Eye2, Eye3];

