import * as React from "react"
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import {ThemeProvider, Global} from "@emotion/react";
import {globalStyles} from "../styles/global";
import {theme} from "../styles/theme";
import {Intro} from "../components/intro";
import {SchoolSection, SectionType} from "../components/school_section";
import {Footer} from "../components/footer";
import {Header} from "../components/header";
import {Filieres} from "../components/filieres";
import {Interviews} from "../components/interviews";
import {HESMarquee} from "../components/marquee";
import  ScrollAnimation  from "react-animate-on-scroll";
import "animate.css/source/_vars.css";
import "animate.css/source/_base.css";
import "animate.css/source/fading_entrances/fadeIn.css"
import "animate.css/source/fading_entrances/fadeInUp.css"
import "animate.css/source/fading_exits/fadeOut.css"

const data = {
    hepia: {
        title: "L’informatique, ton choix le plus ingénieux ?",
        teaser: "Concevoir et développer des applications mobiles, travailler dans la cybersécurité ou encore programmer les systèmes interfaces du futur, tu es dans le parfait timing pour faire partie des acteur•trice•s de l’avenir. HEPIA t'offre diverses orientations qui te permettront de trouver les meilleurs jobs de demain et t'épanouir dans un cadre toujours en mouvement !",
        description: "Si ton approche de l’informatique va bien plus loin que celle d’un•e simple utilisateur•trice, tu as sans doute déjà envisagé d’en faire ton métier. Tu peux franchir le pas à HEPIA, pour ensuite concevoir et développer des applications mobiles, travailler dans la cybersécurité ou encore programmer les systèmes interfaces du futur. Il y a tant à faire dans le monde numérique !\n Et toi, tu es dans le parfait timing pour faire partie des acteur•trice•s de l’avenir. HEPIA t'offre diverses orientations qui te permettront de trouver les meilleurs jobs de demain et t'épanouir dans un cadre toujours en mouvement !\n",
        interview: 3
    },
    heg: {
        title: "L'informatique pour gérer ta carrière ?",
        teaser: "Une entreprise sans informatique ? Impensable ! Et ce n'est que le début… Si tu as déjà un pied dans l'avenir, si tu peux imaginer les incroyables potentiels de la transformation numérique et des technologies autour de la blockchain, ou alors si tu veux te mesurer aux cyber-pirates, la filière Informatique de gestion de la HEG est pour toi !\n ",
        description: "Une entreprise sans informatique ? Impensable ! Et ce n'est que le début… Si tu as déjà un pied dans l'avenir, si tu peux imaginer les incroyables potentiels de la transformation numérique et des technologies autour de la blockchain, ou alors si tu veux te mesurer aux cyber-pirates, la filière Informatique de gestion de la HEG  est pour toi ! \n Pour devenir informaticien•ne en entreprise ou, selon tes goûts, dans une institution, une organisation ou une association, tu bénéficieras vraiment d'une formation en phase avec les réalités du terrain. Car la HEG adapte constamment les contenus de ses formations aux besoins des employeurs.",
        interview: 2
    },
    head: {
        title: "L'informatique pour développer ta fibre artistique ?",
        teaser: "Si tu rêves d'évoluer dans le monde de la création et de la 3D, pas d’hésitation, une formation à la HEAD est ce qu'il te faut. Après avoir développé ta créativité et tes connaissances graphiques en communication visuelle, tu pourras recevoir une formation spécifique de media designer ou de designer d’expériences en réalité virtuelle pour devenir un \"master of the game\". ",
        description: "Dessins, animations, vidéos, jeux… ton ordinateur est ton terrain d’expression favori ? Aujourd’hui, tu as toujours autant envie de passer des heures à créer sur ton écran mais, plus encore, tu veux imaginer les prochains univers visuels des jeux qui vont captiver des milliers de gamers !  \n Pas d'hésitation, une formation à la HEAD est ce qu'il te faut. Après avoir développé ta créativité et tes connaissances graphiques en communication visuelle, tu pourras recevoir une formation spécifique de media designer ou de designer d'expériences en réalité virtuelle pour devenir un \"master of the game\".",
        interview: 1
    }
}

// markup
const IndexPage = () => {
  return (
      <ThemeProvider theme={theme}>
         <Global styles={globalStyles}  />
          <Helmet>
              <title>Change le code | HEAD HEPIA HEG</title>
              <meta name="description" content="Tu es juste à quelques clics de découvrir nos formations numériques !" />
          </Helmet>
          <Header />
          <ScrollAnimation animateIn='fadeIn'
                           animateOut='fadeOut'>
          <Hero />
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'
                           animateOut='fadeOut'>
          <Intro />
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn'
                           animateOut='fadeOut'>
          <SchoolSection  sectionType={SectionType.hepia}   title={data.hepia.title} teaser={data.hepia.teaser} description={data.hepia.description} interview={data.hepia.interview} />
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn'
                           animateOut='fadeOut'>
          <SchoolSection  sectionType={SectionType.head} reverseRow  title={data.head.title} teaser={data.head.teaser} description={data.head.description}  interview={data.head.interview} />
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn'
                           animateOut='fadeOut'>
          <SchoolSection  sectionType={SectionType.heg}   title={data.heg.title} teaser={data.heg.teaser} description={data.heg.description} interview={data.heg.interview}  />
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn'
                           animateOut='fadeOut'>
          <HESMarquee />
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'
                           animateOut='fadeOut'>
          <Interviews />
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'
                           animateOut='fadeOut'>
          <Filieres />
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn'
                           animateOut='fadeOut'>
          <Footer/>
          </ScrollAnimation>
      </ThemeProvider>
  )
}

export default IndexPage