import styled from "@emotion/styled";
import {hideText} from "polished";
import changeLeCodeSvg from "../../images/change-le-code.svg"
import breakpointFrom, {breakpointTo} from "../../styles/breakpoints";

export const HESHeader = styled.header`
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 2rem;
  z-index: 3;
  background-color: ${props => props.theme.colors.lightBlue };
`

export const HeaderLogo = styled.a`
  background: transparent url(${changeLeCodeSvg}) center center no-repeat;
  width: 8.48rem;
  height: 3.12rem;
  background-size: 100% 100%;
  align-self: center;
  ${hideText()}
`

export const HeaderNav = styled.nav`
  align-self: center;
  a {
    display: inline-block;
    margin-left: 1rem;
    text-decoration: none;
    font-family: ${props => props.theme.fonts.menu.family};
    color: ${props => props.theme.fonts.menu.color};
    font-size:  ${props => props.theme.fonts.menu.size};
    :hover { 
      text-decoration: underline;
    }
    &.active {
      text-decoration: underline;
    }
  }
  ${ props => breakpointTo.sm`
    visibility: hidden;
    position: fixed;
    left:0;
    top: 5.9rem;
    width: 100%;
    height: 0;
    transition: all linear 0.5s; 
    overflow: hidden;
    background-color: ${props.theme.colors.lightBlue};
      
    &.open {
      visibility: visible;
      height: 50%;
    }
    a {
      display: block;
      text-align: center;
      margin: 1.5rem 0;
    }
  `}
`

export const Burger = styled.div`
  margin: 0;
  width: 3rem;
  height: auto;
  align-self: center;
  cursor: pointer;
  ${breakpointFrom.sm`
    display: none;
  `}

  &:after,
  &:before,
  div {
    background-color: ${props => props.theme.colors.white};

    border-radius: 3px;
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    margin: 7px 0;
    transition: all .2s ease-in-out;
    overflow: hidden;
    text-indent: 150%;
  }
  
  &.active {
    &:after,
    &:before,
    div {
      background-color: ${props => props.theme.colors.white};
    }
    &:before {
      transform: translateY(9px) rotate(135deg);
    }

    &:after {
      transform: translateY(-9px) rotate(-135deg);
    }

    div {
      transform: scale(0);
    }
  }
`

