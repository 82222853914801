import { css} from "@emotion/react";
import  'sanitize.css';
import { responsiveFontSize, bodyFontStepsFrom } from "./fonts";
import goshaSansEOT from "../fonts/PPGoshaSans-Bold.eot";
import goshaSansTTF from "../fonts/PPGoshaSans-Bold.ttf";
import goshaSansOTF from "../fonts/PPGoshaSans-Bold.otf";
import goshaSansWOFF from "../fonts/PPGoshaSans-Bold.woff";
import goshaSansWOFF2 from "../fonts/PPGoshaSans-Bold.woff2";
import styled from "@emotion/styled";

// styles
export const globalStyles = (theme) => css `
  
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

  @font-face {
    font-family: Gosha Sans;
    src: url(${goshaSansWOFF2}) ;
    src: url(${goshaSansTTF}) format("truetype");
    src: url(${goshaSansEOT}) format("embedded-opentype");
    src: url(${goshaSansOTF}) format("opentype");
    src: url(${goshaSansWOFF}) format("woff");
    font-weight: bold;
  }
  
  
  html {
    min-height: 100%;
    height: 100%;
    ${responsiveFontSize(bodyFontStepsFrom)}
  }
  
  body {
    color: ${theme.fonts.body.color};
    padding: 0;
    margin:0;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: ${theme.fonts.bodySmall.size};
  }
  
  h2 {
    font-size: 1.6rem;
  }
`

export const ConstrainedSection = styled.section`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: ${ props => props.theme.maxWidth}px;
  padding-top: ${ props => props.theme.paddings.top};
  padding-bottom: ${ props => props.theme.paddings.bottom};
  padding-left: ${ props => props.theme.paddings.left};
  padding-right: ${ props => props.theme.paddings.right};
  
`
