import * as React from "react";
import {useEffect, useRef} from "react";
import {Section, Arrow} from "./style";


function calculateRot(bounds, event) {
    const x = (bounds.left) + (bounds.width / 2);
    const y = (bounds.top) + (bounds.height / 2);
    const rad = Math.atan2(event.pageX - x, event.pageY - y);
    return (rad * (180 / Math.PI) * -1) + 180;
}


const Hero = function() {
    const eye1 = useRef();
    const eye2 = useRef()
    let interval, timeout;

    const moveEyes = function(rot) {
        //console.log("applying transform: " +  'rotate(' + rot1 + 'deg)');
        eye1.current.style.transform = 'rotate(' + rot + 'deg)';
        eye2.current.style.transform = 'rotate(' + rot + 'deg)';
    }

    const followMouse = function(event) {
        console.log("Clearing interval", interval, timeout);
        if (interval)
            clearInterval(interval);
       if (!timeout)
            timeout = setTimeout(() => { interval = setInterval(animateEye, 3000); timeout = null; console.log("setting interval", interval) }, 5000)
        eye2.current.style.transition = "";
        eye1.current.style.transition = "";
       const bounds = eye1.current.getBoundingClientRect();
       // console.log("eye2 with", bounds2);
       const rot1 = calculateRot(bounds, event);
       //console.log("applying transform: " +  'rotate(' + rot1 + 'deg)');
       moveEyes(rot1);
    }

    const animateEye = () => {
        const t = Math.random() * 2;
        const r = Math.random() * 360;
        eye2.current.style.transition = "transform ease-in-out "+t+"s";
        eye1.current.style.transition = "transform ease-in-out "+t+"s";
        moveEyes(r);
    }

    useEffect(() => {
        interval = setInterval(animateEye, 3000);
       // console.log("USE EFFECT !!!!", interval, timeout);
    }, []);




    return <Section className={"Section"} onMouseMove={followMouse}>
        <div className='container'>
            <div className='eye' ref={eye1}/>
            <div className='eye' ref={eye2}/>
        </div>
        <Arrow />
    </Section>;
}

export default Hero;