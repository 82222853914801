import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {breakpointTo} from "../../styles/breakpoints";



export const H1 = styled.h1`
  text-align: center;
  font: normal normal bold 3rem/3.5rem Gosha Sans;
  letter-spacing: 0px;
  color: #182E36;
  opacity: 1;
  ${breakpointTo.sm`
    font-size: 2.5rem;
  `}
`

export const P = styled.p `
     text-align: center;
`

const anim = css`
  transition: transform ease-in 0.5s;
  &:hover {
    transform: rotate(180deg);
  }
  
`;

export const Icons = styled.div`
  position:relative; 
  width: 38rem;
  height: 19rem;
  margin-left: -19rem;
  left: 50%;
  .hepia {
    position: absolute;
    left:0;
    bottom:0;
    ${anim}
  }
  .head {
    position: absolute;
    left: 12rem;
    bottom: 2.4rem;
    ${anim}
  }
  .heg {
    position: absolute;
    left: 24rem;
    bottom: 1rem;
    ${anim}
  }
  
  ${breakpointTo.sm`
     width: 20rem;
     height: 12rem;
     margin-left: -10rem;
     .head {
        left: 6rem;
     }
     .heg {
        left: 12rem;
     }
  `}
`
