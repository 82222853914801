import styled from "@emotion/styled";
import {ConstrainedSection} from "../../styles/global";
import {math} from "polished";
import starIcon from "../../images/star.svg";
import {breakpointTo} from "../../styles/breakpoints";

export const InterviewsSection = styled.section`
  margin-top:2rem;
  margin-bottom: 2rem;
  overflow: hidden;


  .swiper {
    width: 120%;
    margin-left: -10%;
    max-width: 120%;
    max-height: 100vh;
    // CSS Grid/Flexbox bug size workaround
    // @see https://github.com/kenwheeler/slick/issues/982
    min-height: 0;
    min-width: 0;
    display:flex;
    flex-direction: column-reverse;
    ${breakpointTo.lg`
        margin-left: 0;
        max-width: 100%;
    `}
  }
  .swiper-button-next, .swiper-button-prev {
    top:100%;
    margin-top: calc(-2px - (var(--swiper-navigation-size)/ 1));
    color: ${ (props) => props.theme.colors.blue };
    z-index: 11;
  }

  .swiper-button-next {
    right: 15%;
    ${breakpointTo.lg`
          right: 5%;
    `}
  }
  .swiper-button-prev {
    left: 15%;
    ${breakpointTo.lg`
          left: 5%;
    `}
  }
  
  .swiper-slide {
    width: 50%;
    height: auto;
    min-height: 100%;
    img {
      width: 100%;
    }
    .video {
      z-index:1;
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      
      iframe {
        
      }
    }
  }

  .swiper-pagination {
    margin-top: 48px;
    position: relative;
  }

  .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: ${(props) => props.theme.colors.lightBlue };
    cursor: pointer;
    opacity: 1;
    &.swiper-pagination-bullet-active  {
      background-color:  ${ (props) => props.theme.colors.blue };
    }
    &:not(:first-of-type) {
      margin-left: 18px;
    }
  }


`

export const Intro= ConstrainedSection.withComponent('div');

export const Title = styled.h2`
  text-align: center;
  font: normal normal bold 3rem/3.5rem Gosha Sans;
  letter-spacing: 0px;
  color: #182E36;
  opacity: 1;
`

export const P = styled.p `
     text-align: center;
`
