import {css} from "@emotion/react";
const breakpoints = {
    xs: 320,
    sm: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1980,
}
/*
$bp-large-mobile: 412px;
$bp-tablet: 480px;
$bp-news-nav: 584px;
$bp-mobile-horizontal: 736px;
$bp-small: 800px;
$bp-desktop: 1024px;
$bp-large: 1280px;
$bp-xlarge: 1920px;
 */
const devices = {
    xs: `@media only screen  and (min-width: ${breakpoints.xs}px)`,
    sm: `@media only screen  and (min-width: ${breakpoints.sm}px)`,
    lg: `@media only screen  and (min-width: ${breakpoints.lg}px)`,
    xl: `@media only screen  and (min-width: ${breakpoints.xl}px)`,
    xxl: `@media only screen  and (min-width: ${breakpoints.xxl}px)`
}
/**
 * Usage
 * ${breakpointFrom.sm`
    background-color: aqua;
  `}
 * @type {{}}
 */

const  breakpointFrom = Object.keys(breakpoints).reduce(
    (accumulator, label) => {
        accumulator[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}px) {
        ${css(...args)};
      }
    `;
        return accumulator;
    },
    {}
);
const  breakpointTo = Object.keys(breakpoints).reduce(
    (accumulator, label) => {
        accumulator[label] = (...args) => css`
      @media (max-width: ${breakpoints[label]}px) {
        ${css(...args)};
      }
    `;
        return accumulator;
    },
    {}
);


export default breakpointFrom;
export  {breakpoints, devices, breakpointFrom, breakpointTo}