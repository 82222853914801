import styled from "@emotion/styled";
import headIcon from "../images/head-pen.svg"
import hegIcon from "../images/heg-folder.svg"
import hepiaIcon from "../images/hepia-lightbulb.svg"
import folderIcon from "../images/folder.svg"
import handIcon from "../images/hand.svg"
import {hideText, math} from "polished";
import starIcon from "../images/star.svg";
import {breakpointTo} from "./breakpoints";
import {ScrollLink} from "react-scroll";

const IconLink = styled.a`
  display: inline-block;
  background-size: 100% 100%;
  width: 14rem;
  height: 14rem;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  ${hideText()}
  ${breakpointTo.sm`
    width: 8rem;
    height: 8rem;
  `}
`

const HeadIconLink = ScrollLink(styled(IconLink)`
    background-image:  url(${headIcon}) ;
`)

const HegIconLink = ScrollLink(styled(IconLink)`
  background-image:  url(${hegIcon});
`)

const HepiaIconLink = ScrollLink(styled(IconLink)`
  background-image:  url(${hepiaIcon});
`)

const FolderIcon = styled.span`
  display: inline-block;
  background: transparent url(${folderIcon}) center center no-repeat;
  background-size: 100% 100%;
  width: ${ props => props.width ? props.width : "3.7rem"};
  height: ${ props => props.width ? math(props.width + " * 56/79" ) : "2.6rem"};
`

const HandIcon = styled.span`
  display: inline-block;
  background: transparent url(${handIcon}) center center no-repeat;
  background-size: 100% 100%;
  width: ${ props => props.width ? props.width : "2.19rem"};
  height: ${ props => props.width ? math(props.width + " * 46/68" ) : "3.23rem"};
`

export const StarIcon = styled.span`
  display: inline-block;
  background: transparent url(${starIcon}) center center no-repeat;
  background-size: 100% 100%;
  width: ${ props => props.width ? props.width : "3.76rem"};
  height: ${ props => props.width ? math(props.width + " * 56/79" ) : "2.66rem"};
`


export {HepiaIconLink, HegIconLink, HeadIconLink, FolderIcon, HandIcon};