import {breakpoints} from "./breakpoints";
import {css} from "@emotion/react";
/*
// Body

$body-font-responsive-steps: (
    default: 14px,
    from-tablet: 16px,
    from-small: 16px,
    from-desktop: 15px,
    from-large: 16px,
    from-xlarge: 17px
);

// Input

$input-font-responsive-steps: (
    default: 14px,
    from-tablet: 15px,
    from-small: 16px,
    from-desktop: 17px,
    from-large: 18px,
    from-xlarge: 19px
);

 */
const calculateFontRatio = (minSize, maxSize, targetSize = 16) => {
    let medSize = 0;
    const min = minSize ? parseFloat(minSize) : 0;
    const max = maxSize ? parseFloat(maxSize) : 0;
    const target = targetSize ? parseFloat(targetSize) : 0;
    if (min > 0 && max > 0)
        medSize = (min + max) / 2;
    else
        medSize = min * 1.33;
    return (target / medSize * 100) + "vw";
}

const findStepSize = (viewWidth, fontStepsMap) => {
    let stepSize = {breakpointName: 'undefined', breakpointWidth: '0px', fontSize: '1px'}
    for (const [breakpoint, width] of Object.entries(breakpoints)) {
        let size = 0;
        if (fontStepsMap[breakpoint])
            size = fontStepsMap[breakpoint];
        else
            size = fontStepsMap['default'];
        stepSize = {breakpointName: breakpoint, breakpointWidth: parseInt(width), fontSize: size}
        if ( viewWidth < parseInt(width) )
           return stepSize;
    }
    return stepSize;
}

const responsiveFontSize = (fontSteps, fromMinWidth = true) => {
    const stepWidth = 100;
    let style = css``;
    const from = fromMinWidth ? 'min-width':'max-width';
    for (let i = 3;  i < 20; i++) {
        const startStepWidth =  i * stepWidth;
        const endStepWidth = startStepWidth + stepWidth;
        const stepSize = findStepSize(startStepWidth, fontSteps);
        const fontSize = calculateFontRatio(startStepWidth, endStepWidth, stepSize.fontSize, stepSize.breakpointName);
        const respStyle = css`
          @media only screen and (${from}: ${startStepWidth+'px'}) {
            font-size: ${fontSize};
          }
        `
        style = fromMinWidth ? css`${style} ${respStyle}`: css`${respStyle} ${style}`;
    }
    return style;
}

const bodyFontStepsFrom = {
    default: '14px',
    xs: '16px',
    sm: '17px',
    lg: '19px',
    xl: '21px',
    xxl: '23px'
}

export { bodyFontStepsFrom, responsiveFontSize };