import * as React from "react";
import footerSvg from '../../images/footer-students.svg';
import {HESFooter, HESsoLogo, Parenti} from "./style";

export const Footer = () => {
    return <HESFooter>
        <img src={footerSvg}  alt={"HES-SO"} className={"footerImage"}/>
        <HESsoLogo href={"https://www.hesge.ch/geneve/"} target={"_blank"}>Hes-so</HESsoLogo>
        <Parenti href={"https://www.parentidesign.com"} target={"_blank"}>2022 design by Parenti Design: The Branding Studio</Parenti>
    </HESFooter>
}