import * as React from "react";
import Marquee from "react-fast-marquee";
import {Label, eyes, marqueeStyle} from "./style";

const data = [
    "Les métiers du numérique",
    "Concepteur·trice d’applications mobiles",
    "Community manager",
    "Graphiste",
    "Ingénieur·e en cybersécurité",
    "Gestionnaire de contenus web",
    "Designer de jeux vidéos",
    "Concepteur·trice logiciel",
    "Responsable de la sécurité informatique",
    "Designer d’interaction",
    "Consultant·e informatique",
    "Les métiers du numérique",
    "Administrateur·trice de réseaux",
    "Webdesigner",
    "Développeur·euse de systèmes embarqués",
    "Architecte IT",
    "Game designer",
    "Ingénieur·e système et réseau",
    "Administrateur·trice de bases de données",
    "Concepteur·trice d’images 3d",
    "Programmeur·euse",
    "Motion designer"

];



export const HESMarquee = (props) => {
    return <Marquee gradient={false} css={marqueeStyle} speed={100}>
        {data.map( (label, i) => {
            const Eye = eyes[i % 3];
            return  <> <Label key={"label" + i}>{label}</Label> <Eye key={"eye" + i}/> </>
        })}
    </Marquee>
}