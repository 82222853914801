import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {hideText, math} from "polished";
import hepiaIcon from "../../images/icon_hepia.png";
import headIcon from "../../images/icon_head.png";
import hegIcon from "../../images/icon_heg.png";
import {ConstrainedSection} from "../../styles/global";
import {breakpointTo} from "../../styles/breakpoints";

export const FilieresSection = styled(ConstrainedSection)`
  margin-top:2rem;
  margin-bottom: 2rem;
`

export const Title = styled.h2`
  font-family: ${props => props.theme.fonts.subtitle.family};
  font-weight: ${props => props.theme.fonts.subtitle.weight};
  font-size: ${props => props.theme.fonts.subtitle.size};
  color: ${props => props.theme.fonts.subtitle.color};
  text-align: center;
  margin-bottom: 2rem;
`

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  ${breakpointTo.sm`
    flex-direction: column;
    align-items: center;
  `}
`

const baseIcon = props => css`
  display: inline-block;
  width: ${props.width ? props.width : '376px'};
  height: ${props.width ? math(props.width+' * 405/376') : '405px'};
  background-size: 100% 100%;
  margin: 1rem;
  transition: transform linear 0.5s;
  :hover {
    transform: scale(1.1);
  }
  ${hideText()}
`

export const HeadIconLink = styled.a`
  background: transparent url(${headIcon}) center center no-repeat;
  ${baseIcon}
`

export const HepiaIconLink = styled.a`
  background: transparent url(${hepiaIcon}) center center no-repeat;
  ${baseIcon}

`
export const HegIconLink = styled.a`
  background: transparent url(${hegIcon}) center center no-repeat;
  ${baseIcon}
`