import styled from "@emotion/styled";
import hesSoLogo from "../../images/Hes-so_logo.svg";
import {hideText} from "polished";
import {breakpointTo} from "../../styles/breakpoints";

export const HESFooter = styled.footer`
  margin-top: 3rem;
  position:relative;
  overflow: hidden;
  width: 100%;
  .footerImage {
    width:100%;
    position:relative;
    ${breakpointTo.sm`
      width: auto;
      height: 30rem;
      left:50%;
      transform: translate(-50%,0);
    `}
  }
`

export const HESsoLogo = styled.a`
  position: absolute;
  background: transparent url(${hesSoLogo}) center center no-repeat;
  width: 15rem;
  height: 4rem;
  background-size: 100% 100%;
  left: 2rem;
  bottom: 2rem;
  ${hideText()}
  ${breakpointTo.sm`
    width: 7.5rem;
    height: 2rem;
    left: 1rem;
    bottom: 1rem;
  `}
`

export const Parenti = styled.a`
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  color: #BEBEBE;
  font-size: 0.9rem;
  text-decoration: none;
  ${breakpointTo.sm`
    font-size: 0.5rem;
    right: 1rem;
    bottom: 1rem;
  `}
`