import * as React from "react";
import {Burger, HeaderLogo, HeaderNav, HESHeader} from "./style";
import {Link} from "react-scroll";
import {useState} from "react";


export const Header = () => {
    const [open, setOpen] = useState(false);
    return <HESHeader>
        <HeaderLogo href="https://www.hesge.ch/geneve/" target="_blank">HES-so</HeaderLogo>
        <HeaderNav className={open ? 'open' : '' }>
            <Link to={"hepia"} href={"#hepia"} activeClass="active"  hashSpy spy smooth offset={-100} onClick={() => setOpen(false)}>HEPIA</Link>
            <Link to={"head"} href={"#head"} activeClass="active" hashSpy spy smooth offset={-100} onClick={() => setOpen(false)}>HEAD</Link>
            <Link to={"heg"} href={"#heg"} activeClass="active" hashSpy spy smooth offset={-100} onClick={() => setOpen(false)}>HEG</Link>
            <Link to={"interviews"} href={"#interviews"} activeClass="active" hashSpy spy smooth offset={-100} onClick={() => setOpen(false)}>INTERVIEWS</Link>
        </HeaderNav>
        <Burger className={open ? 'active' : '' } onClick={(e) => setOpen(!open)}><div>Menu</div></Burger>
    </HESHeader>
}