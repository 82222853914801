import {breakpoints} from "./breakpoints";

const colors = {
    black: '#0F1517',
    lightBlue: '#B5E2FB',
    darkGreen: '#182E36',
    blue: '#079EE2',
    white: '#fff',
}
export const theme = {
    colors: colors,
    blockBackgroundColor: colors.lightBlue,
    breakpoints:  breakpoints,
    fonts: {
        menu : {
            family: 'Gosha Sans',
            size: '1rem',//21px
            color: colors.black,
            weight: 'bold'
        },
        title : {
            family: 'Gosha Sans',
            size: '3rem', //64px
            color: colors.darkGreen,
            weight: 'bold'
        },
        subtitle: {
            family: 'Gosha Sans',
            size: '2.6rem',//55px
            color: colors.black,
            weight: 'bold'
        },
        body: {
            family: 'Roboto',
            size: '1.7rem', //36px
            color: colors.black,
            weight: 'normal'

        },
        bodySmall: {
            family: 'Roboto',
            size: '1rem', //21px
            color: colors.black,
            weight: 'bold'
        }
    },
    button: {
        borderColor: colors.blue,
        backgroundColor: colors.white,
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '1.7rem',//36px
        color: colors.black,
    },
    paddings: {
        top: '3rem',
        bottom: '4rem',
        right: '2rem',
        left: '2rem',
    },
    maxWidth : 1400,

};