import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {breakpointTo} from "../../styles/breakpoints";
import {math} from "polished";

export const Section = styled.section`
    display: flex;
    flex-direction: ${props => props.reverse ? 'row-reverse': 'row'} ;
    min-height: 200px;   
    ${breakpointTo.sm`
        flex-direction: column;       
    `}
`

export const AnimCol = styled.div`
  width: 50%;
  min-height: 100%;
  background-color: ${props => props.theme.colors.blue};
  display: flex;
  ${breakpointTo.sm`
        width: 100%;
   `}
`
export const TextCol = styled.div`
  width: 50%;
  min-height: 100%;
  background-color: ${props => props.theme.colors.lightBlue};
  padding: 3rem 2rem 4rem 2rem;
  
  img {
    max-width: 80%;
  }
  ${breakpointTo.sm`
        width: 100%;
  `}
`

export const buttonCss = theme => css`
  display:inline-block;
  min-width: 200px;
  background-color: ${theme.button.backgroundColor};
  border: 0px solid ${theme.button.borderColor };
  color: ${theme.button.borderColor };
  font-weight: ${theme.button.fontWeight };
  font-family: ${theme.button.fontFamily };
  font-size: ${theme.button.fontSize };
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  border-radius: 50px;
  text-decoration: none;
  transition: color linear 0.5s;
  text-align: center;
  cursor: pointer;
  :hover {
    color: ${theme.button.color };
  } 
  ${breakpointTo.sm`
    font-size: 1rem;
  `}
`;

export const popupCss = theme => css`
  padding: 1rem;
  overflow: auto;
  height: 100%;
  background-color: ${theme.colors.lightBlue};
  img {
    max-width: 100%;
  }
  .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 0.1rem 0.3rem;
    line-height: 1rem;
    right: -10px;
    top: -10px;
    font-size: 1.1rem;
    background: #ffffff;
    border-radius: 1rem;
    border: 1px solid ${theme.colors.blue};
    color: ${theme.colors.blue};
  }
  
`;