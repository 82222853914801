import * as React from "react";
import {AnimCol, Section, TextCol, Button, popupCss, buttonCss} from "./style";
import {useEffect, useState} from "react";
import Lottie from "react-lottie-player";
//import ampouleAnim from '../../static/lottie/HES_ampoule.json'
import headIcon from  "../../images/head.svg";
import hegIcon from  "../../images/heg.svg";
import hepiaIcon from  "../../images/hepia.svg";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import {Link} from "react-scroll";
import {HeadIconLink, HegIconLink, HepiaIconLink} from "../filieres/style";
import {goToInterview} from "../interviews";

export const SectionType = {
    head: {
        alt: 'HEAD',
        anim: '/lottie/HES_crayon.json',
        icon: headIcon,
        backgroundColor: '#0081bb',
        iconAlign: 'flex-end',
        id: 'head',
        link: HeadIconLink,
        url: "https://www.hesge.ch/head/formations-recherche/bachelor-en-communication-visuelle"
    },
    hepia: {
        alt: 'HEPIA',
        anim: '/lottie/HES_ampoule.json',
        icon: hepiaIcon,
        backgroundColor: '#0097ff',
        iconAlign: 'center',
        id: 'hepia',
        link: HepiaIconLink,
        url: "https://www.hesge.ch/hepia/bachelor/informatique-et-systemes-communication"
    },
    heg: {
        alt: 'HEG',
        anim: '/lottie/HES_dossier.json',
        icon: hegIcon,
        backgroundColor: '#1d272a',
        iconAlign: 'center',
        id: 'heg',
        link: HegIconLink,
        url: "https://www.hesge.ch/heg/formations/bachelors/informatique-gestion"
    }
}

/*
                <HeadIconLink width={'13rem'} href={"https://www.hesge.ch/head/formations-recherche/bachelor-en-communication-visuelle"} target={"_blank"}>HEAD</HeadIconLink>
                <HepiaIconLink width={'13rem'} href={"https://www.hesge.ch/hepia/bachelor/informatique-et-systemes-communication"} target={"_blank"}>HEPIA</HepiaIconLink>
                <HegIconLink width={'13rem'} href={"https://www.hesge.ch/heg/formations/bachelors/informatique-gestion"} target={"_blank"}>HEG</HegIconLink>

 */


export const SchoolSection = (props) => {

    const [animationData, setAnimationData] = useState();

    useEffect(() => {
        fetch(props.sectionType.anim)
            .then(response => response.json()  )
            .then(data => { setAnimationData(data)} );
       //
    }, [props.sectionType.anim]);
    const KnowMore = (props) => (
        <Popup trigger={<strong style={{cursor: "pointer"}}> Lire la suite... </strong>} modal contentStyle={{ width: '90%', maxWidth: '800px', height: '90%', maxHeight: '800px'}}>
        {close => {
            const ALink = props.sectionType.link;
            return <div className="modal" css={popupCss} >
                <a className="close" onClick={close}>&times;</a>
                <a href={props.sectionType.url} target={"_blank"}> <img alt={props.sectionType.alt} src={props.sectionType.icon} /> </a>
                <h2> {props.title} </h2>
                <p dangerouslySetInnerHTML={{__html: props.description.replaceAll("\n", "<br/><br/>")}} />
                <p style={{textAlign: "center"}}>
                    <ALink href={props.sectionType.url} width={'10rem'} target={"_blank"}>{props.sectionType.alt}</ALink>
                </p>
            </div>
        }}
        </Popup>
    );
    const Anim = !animationData ? <AnimCol style={{backgroundColor: props.sectionType.backgroundColor}}> <div>Loading...</div> </AnimCol> : <AnimCol style={{backgroundColor: props.sectionType.backgroundColor}}><Lottie play loop style={ {width: '100%', alignSelf: props.sectionType.iconAlign } } animationData={animationData} /></AnimCol>;
    const Text = <TextCol> <img alt={props.sectionType.alt} src={props.sectionType.icon}  /> <h2> {props.title} </h2> <p>{props.teaser} <KnowMore {...props} /> </p> <Link to={"interviews"} onClick={(e) => goToInterview(props.interview) } hashSpy spy smooth offset={-100} css={buttonCss}>Voir les témoignages</Link></TextCol>;
    return <Section id={props.sectionType.id} reverse={props.reverseRow} >
        {Anim} {Text}
    </Section>

}